// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../static/images/admin/calque-9.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".rowQuality {\n  margin-left: -15px;\n  margin-right: -225px;\n  padding-right: 10%;\n  display: flex;\n  flex-wrap: wrap;\n  flex-direction: row;\n}\n\n.activeQuality {\n  background-color: #7bd812;\n  margin-bottom: 2%;\n}\n\n.itemQP {\n  margin-bottom: 10px;\n  background-color: #7bd812;\n  margin-left: -15px;\n  margin-right: 60px;\n}\n\n.okIcon::after {\n  padding: 5%;\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.qpHeight {\n  height: 100px !important;\n  cursor: pointer;\n}\n\n.disabled-qp .box {\n  background: #c1c1c1;\n}\n\n.disabled-qp .box:hover {\n  background: #c1c1c1;\n}\n\n.qp-title {\n  font-size: 20px !important;\n  margin-bottom: 15px !important ;\n  opacity: 0.5 !important;\n}\n.qp-text-color {\n  color: #ffffff !important;\n}\n\n#quality-program-title {\n  color: #c6c6c6;\n  font-size: 14px;\n  padding-left: 1%;\n}\n\n#quality-program-title .box .active {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n}\n\n#quality-program-title .box.active img {\n  width: 25px !important;\n}\n\n#quality-program-title .box label {\n  margin-top: 0 !important;\n}\n\n#quality-program-title .box {\n  padding: 20px 0 20px !important;\n}\n\n#quality-program-title .quality-program-title-inner {\n  padding-bottom: 2%;\n}\n", ""]);
// Exports
module.exports = exports;
